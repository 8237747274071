import base64 from 'react-native-base64';
import { firebase } from '../config/configureFirebase';
import AccessKey from './AccessKey';

const fetchFromApi = async (body) => {
    const { config } = firebase;
    const response = await fetch(`https://${config.projectId}.web.app/googleapi`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            "Authorization": "Basic " + base64.encode(config.projectId + ":" + AccessKey)
        },
        body: JSON.stringify(body)
    });
    return response.json();
}

export const fetchPlacesAutocomplete = async (searchKeyword, sessionToken) => {
    try {
        // تحقق من أن searchKeyword ليس فارغًا أو قصيرًا جدًا
        if (!searchKeyword || searchKeyword.length < 3) {
            throw new Error("الكلمة المفتاحية قصيرة جدًا");
        }

        const body = { "searchKeyword": searchKeyword, "sessiontoken": sessionToken };
        const json = await fetchFromApi(body);

        if (json && json.searchResults) {
            return json.searchResults;
        } else {
            throw new Error(json.error);
        }
    } catch (error) {
        console.log(error);
        throw new Error("حدث خطأ أثناء استرجاع نتائج البحث");
    }
};

export const fetchCoordsfromPlace = async (place_id) => {
    try {
        // تحقق من أن place_id ليس فارغًا أو null
        if (!place_id || place_id.length === 0) {
            throw new Error("معرف المكان غير صالح");
        }

        const body = { "place_id": place_id };
        const json = await fetchFromApi(body);

        if (json && json.coords) {
            return json.coords;
        } else {
            throw new Error(json.error);
        }
    } catch (error) {
        console.log(error);
        throw new Error("حدث خطأ أثناء استرجاع الإحداثيات");
    }
};

export const fetchAddressfromCoords = async (latlng) => {
    try {
        // تحقق من أن latlng ليس فارغًا أو null
        if (!latlng || latlng.length === 0) {
            throw new Error("الإحداثيات غير صالحة");
        }

        const body = { "latlng": latlng };
        const json = await fetchFromApi(body);

        if (json && json.address) {
            return json.address;
        } else {
            throw new Error(json.error);
        }
    } catch (error) {
        console.log(error);
        throw new Error("حدث خطأ أثناء استرجاع العنوان");
    }
};

export const getDistanceMatrix = async (startLoc, destLoc) => {
    try {
        // تحقق من أن startLoc و destLoc ليسا فارغين
        if (!startLoc || !destLoc) {
            throw new Error("المواقع غير صالحة");
        }

        const body = { "start": startLoc, "dest": destLoc, "calltype": "matrix" };
        const json = await fetchFromApi(body);

        if (json.error) {
            throw new Error(json.error);
        } else {
            return json;
        }
    } catch (error) {
        console.log(error);
        throw new Error("حدث خطأ أثناء استرجاع بيانات المسافة");
    }
};

export const getDirectionsApi = async (startLoc, destLoc, waypoints) => {
    try {
        // تحقق من أن startLoc و destLoc ليسا فارغين
        if (!startLoc || !destLoc) {
            throw new Error("المواقع غير صالحة");
        }

        const body = { "start": startLoc, "dest": destLoc, "calltype": "direction" };
        if (waypoints) {
            body["waypoints"] = waypoints;
        }
        const json = await fetchFromApi(body);

        if (json.hasOwnProperty('distance_in_km')) {
            return json;
        } else {
            throw new Error(json.error);
        }
    } catch (error) {
        console.log(error);
        throw new Error("حدث خطأ أثناء استرجاع بيانات الاتجاهات");
    }
};
